<template>
  <div>
    <div class="erp-form__header header-bar__sticky pd16">
      <div class="erp-form__title">查看来票</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="toUnion" v-if="formType == 5 && hasPermission('erp:input:invoiceRefOrder')">关联</div>
        <div class="erp-primary__btn" @click="toEdit" v-if="formType == 5 && hasPermission('erp:input:operateFmInvoice')">编辑</div>
        <div class="erp-primary__btn plain" @click="openCancelDialog" v-if="formType == 5 && hasPermission('erp:input:operateFmInvoice')">删除</div>
        <div @click="toReUnion" v-if="formType == 6 && hasPermission('erp:input:invoiceRefOrder')" class="erp-primary__btn">重新关联</div>
        <div class="erp-primary__btn ml-8" @click="releaseUnion" v-if="formType == 6 && hasPermission('erp:input:invoiceRefOrder')">解除关联</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp padding_20 op-16">
      <el-row :gutter="30" type="flex" class="flex-warp">
        <el-col :span="12">
          <erp-view label="销售方名称" :value="database.sellerName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="开票类型" :value="database.invoiceTypeName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="本次来票金额" :value="database.totalAmountDesc"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="开票日期" :value="database.invoiceCreatedTime"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="发票号" :value="database.invoiceNumber"></erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view label="发票备注" :value="database.remark"></erp-view>
        </el-col>
      </el-row>
      <div class="erp-form__title f16" style="margin-top: 31px">发票原文件</div>
      <el-row :gutter="30" type="flex" class="flex-warp">
        <el-col :span="12">
          <erp-view label="来票文件" :value="database.attachmentVo ? database.attachmentVo.name : ''">
            <div class="card-preview" v-if="database.attachmentVo && database.attachmentVo.name">
              <span class="file-width">{{ database.attachmentVo.name }}</span>
              <img src="../../../../assets/images/preview__zoom.png" alt="" @click="$showFiles(database.attachmentVo.name, database.attachmentVo.url)" />
              <img
                src="../../../../assets/images/preview_download.png"
                alt=""
                @click="$showFiles(database.attachmentVo.name, database.attachmentVo.url, false)"
              />
            </div>
            <div v-else>-</div>
          </erp-view>
        </el-col>
      </el-row>
    </div>
    <div v-if="formType == 6">
      <div class="erp-form__title f16" style="margin-top: 30px; margin-left: 20px">关联订单</div>
      <div class="order-base__warp mt-12">
        <!-- <el-col :span="24" style="margin-top: 12px"> -->
        <el-table
          :show-summary="database.inputOrderVos && database.inputOrderVos.length > 1"
          :summary-method="getSummaries"
          :data="database.inputOrderVos"
          border
          class="egrid erp-table__list erp-view__table td-40 summary-table"
          max-height="520"
        >
          <el-table-column label="销售合同号" prop="businessContractNo" class-name="no-pd" align="center" show-overflow-tooltip width="180">
            <template #default="scope">
              <div :class="{'record__link':scope.row.businessContractNo}" @click="linkTo(scope.row)">
                {{ filterName(scope.row.businessContractNo) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="签约主体" prop="contractPrincipalName" class-name="no-pd" align="center" show-overflow-tooltip width="120">
            <template #default="scope">
              <div>
                {{ filterName(scope.row.contractPrincipalName) }}
              </div>
            </template> 
          </el-table-column>
          <el-table-column prop="goodsInvoiceAmount" align="center" class-name="no-pd" min-width="150" show-overflow-tooltip>
            <template v-slot:header>
                <span>已来货物发票金额<br/>（不含税）</span>
              </template>
            <template #default="scope">
              {{ filterName(scope.row.goodsInvoiceAmountDesc) }}
            </template>
          </el-table-column>
          <el-table-column prop="goodsInvoiceTaxAmount" class-name="no-pd" align="center" show-overflow-tooltip min-width="150">
            <template v-slot:header>
                <span>已来货物发票<br/>税额</span>
              </template>
            <template #default="scope">
              <div>
                {{ filterName(scope.row.goodsInvoiceTaxAmountDesc) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="serviceInvoiceAmount"
            class-name="no-pd"
            align="center"
            show-overflow-tooltip
          >
            <template v-slot:header>
                <span>已来服务发票金额<br/>（不含税） </span>
              </template>
            <template #default="scope">
              <div>
                {{ filterName(scope.row.serviceInvoiceAmountDesc) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="serviceInvoiceTaxAmount" class-name="no-pd" align="center" show-overflow-tooltip min-width="150">
            <template v-slot:header>
                <span>已来服务发票<br/>税额 </span>
              </template>
            <template #default="scope">
              {{ filterName(scope.row.serviceInvoiceTaxAmountDesc) }}
            </template>
          </el-table-column>
        </el-table>
        <!-- </el-col> -->
      </div>
    </div>
  </div>
</template>
<script>
import { loadPage } from '@/utils/publicMethods';
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 // formType 1录入来票(新增)    2录入来票(编辑)  3关联来票弹窗  4重关联来票弹窗  5查看来票-未关联弹窗  6查看来票-已关联弹窗
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      database: {
        attachmentVo: {
          name: null,
          url: null,
        },
        id: null,
        inputOrderVos: [],
        invoiceCreatedTime: null,
        invoiceNumber: null,
        invoiceType: null,
        invoiceTypeName: null,
        remark: null,
        sellerName: null,
        totalAmount: null,
        version: 0,
        goodsInvoiceTotalAmount: null, // 已来货物发票合计金额(不含税)(元)
        goodsInvoiceTotalAmountDesc: '', // 已来货物发票合计金额(不含税)(元) (展示用)
        goodsInvoiceTaxTotalAmount: null, // 已来货物发票合计税额(元)
        goodsInvoiceTaxTotalAmountDesc: '', // 已来货物发票合计税额(元) (展示用)
        serviceInvoiceTotalAmount: null, // 已来服务发票合计金额(不含税)(元)
        serviceInvoiceTotalAmountDesc: '', // 已来服务发票合计金额(不含税)(元)(展示用)
        serviceInvoiceTaxTotalAmount: null, // 已来服务发票合计税额(元)
        serviceInvoiceTaxTotalAmountDesc: '', // 已来服务发票合计税额(元) (展示用)
      },
    };
  },
  created() {
    this.getinitdata();
  },
  methods: {
    linkTo(row){
      loadPage('OrderDetail', {
        orderId: row.orderId,
        version: row.version,
        name: 'vote',
      });
    },
    getSummaries() {
      const sumArr = ['合计', ''];
      const propsArr = ['goodsInvoiceTotalAmountDesc', 'goodsInvoiceTaxTotalAmountDesc', 'serviceInvoiceTotalAmountDesc', 'serviceInvoiceTaxTotalAmountDesc'];
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      propsArr.forEach((prop) => {
        // forEach 的回调函数里的 this 需要在调用 forEach 的时候显式指定。如果没有指定，那么 this 是 undefined
        let res = that.database[prop];
        if (res === null) {
          res = '-';
        }
        sumArr.push(res);
      });
      return sumArr;
    },
    // 解除关联
    releaseUnion() {
      this.$erpConfirm('确定解除来票和订单之间的关联吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/input/dissociated',
            data: {
              id: this.database.id,
              version: this.database.version,
            },
            success: (res) => {
              if (res.code === 200) {
                this.successTip('已解除来票和订单的关联');
                this.getinitdata();
                this.$emit('cancel', true, 5);
              } else {
                //                  this.DialogData.dialogVisible = false;
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 编辑
    toEdit() {
      // formType 1录入来票(新增)    2录入来票(编辑)  3关联来票弹窗  4重关联来票弹窗  5查看来票-未关联弹窗  6查看来票-已关联弹窗
      this.$emit('cancel', true, 2, 5);
    },
    // 关联
    toUnion() {
      // formType 1录入来票(新增)    2录入来票(编辑)  3关联来票弹窗  4重关联来票弹窗  5查看来票-未关联弹窗  6查看来票-已关联弹窗
      this.$emit('cancel', true, 3, 5);
    },
    // 重新关联
    toReUnion() {
      // formType 1录入来票(新增)    2录入来票(编辑)  3关联来票弹窗  4重关联来票弹窗  5查看来票-未关联弹窗  6查看来票-已关联弹窗
      this.$emit('cancel', true, 4, 6);
    },
    openCancelDialog() {
      this.$erpConfirm('确定删除来票吗？')
        .then(() => {
          this.ajax({
            type: 'POST',
            url: '/malicrm/input/deleteInvoice',
            data: {
              id: this.database.id,
              version: this.database.version,
            },
            success: (res) => {
              if (res.code === 200) {
                this.successTip('删除成功', 3000);
                this.$emit('cancel', true, 0);
              } else {
                this.errorTip(res.message);
              }
            },
          });
        })
        .catch(() => {
          return false;
        });
    },
    /**
     * 提交事件
     */
    edit() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/sm/deliver/checkDeliver/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            // this.database = res.result;
            if (res.result) {
              this.$emit('edit');
            } else {
              this.errorTip('结算单已关联收款，无法编辑');
            }
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },

    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    getinitdata() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/input/invoiceDetail/' + this.editData.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            this.database = res.result;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.viewform__one_body {
  display: flex;
  // flex: 1;
  width: 50%;
}
.viewform__main {
  display: flex;
  flex-direction: row;
}
.padding__top {
  padding-top: 20px;
}
.viewfrom_body_title {
  display: flex;
  width: 90px;
  color: rgba(115, 124, 140, 1);
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}
.viewfrom_body_value {
  display: flex;
  flex: 1;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  padding-left: 10px;
  padding-bottom: 10px;
  line-height: 15px;
  border-bottom: 1px rgba(220, 223, 230, 1) solid;
}
.viewfrom_body {
  width: 100%;
  min-height: 42px;
  display: flex;
  padding: 8px 12px;
  flex-direction: row;
}
.card-preview .file-width {
  width: 306px;
}
.erp-form__header.pd16 {
  padding-left: 16px;
  padding-right: 16px;
}
.mt-12 {
  margin-top: 12px;
}
</style>
