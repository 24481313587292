import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!
  const _component_importReceivable = _resolveComponent("importReceivable")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!
  const _component_income_ticket = _resolveComponent("income-ticket")!
  const _component_relation_ticket = _resolveComponent("relation-ticket")!
  const _component_check_ticket = _resolveComponent("check-ticket")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErpList, {
      url: "/malicrm/input/queryFmInputListWithPage",
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "vote",
      onRowClick: _ctx.rowClick,
      customParams: _ctx.customParams
    }, {
      header: _withCtx(() => [
        (_ctx.hasPermission('erp:payment:exportFmPaymentList'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "erp-default__btn ml-8",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportFileFn && _ctx.exportFileFn(...args)))
            }, "导出"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:input:operateFmInvoice'))
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "erp-primary__btn ml-8",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.incomingTicket && _ctx.incomingTicket(...args)))
            }, "录入来票"))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "erp-primary__btn ml-8",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.exportInFile && _ctx.exportInFile(...args)))
        }, "导入来票")
      ]),
      _: 1
    }, 8, ["columOptions", "onRowClick", "customParams"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible && _ctx.improtVisible,
      onClose: _ctx.cancel,
      erp: "",
      class: "fix-top-header",
      width: _ctx.importReceiWidth
    }, {
      default: _withCtx(() => [
        (_ctx.improtVisible)
          ? (_openBlock(), _createBlock(_component_importReceivable, {
              key: 0,
              onCancel: _ctx.cancelCallBack,
              onChangeWidth: _ctx.changeImportWidth
            }, null, 8, ["onCancel", "onChangeWidth"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onClose", "width"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible && (_ctx.formType == 1 || _ctx.formType == 2),
      onClose: _ctx.cancel,
      erp: "",
      class: "fix-top-header"
    }, {
      default: _withCtx(() => [
        (_ctx.formType == 1 || _ctx.formType == 2)
          ? (_openBlock(), _createBlock(_component_income_ticket, {
              key: 0,
              formType: _ctx.formType,
              id: _ctx.rowData.id,
              preFormType: _ctx.preFormType,
              onCancel: _ctx.cancelCallBack
            }, null, 8, ["formType", "id", "preFormType", "onCancel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onClose"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible && (_ctx.formType == 3 || _ctx.formType == 4),
      onClose: _ctx.cancel,
      erp: "",
      class: "fix-top-header",
      width: 1100
    }, {
      default: _withCtx(() => [
        (_ctx.formType == 3 || _ctx.formType == 4)
          ? (_openBlock(), _createBlock(_component_relation_ticket, {
              key: 0,
              formType: _ctx.formType,
              preFormType: _ctx.preFormType,
              id: _ctx.rowData.id,
              onCancel: _ctx.cancelCallBack
            }, null, 8, ["formType", "preFormType", "id", "onCancel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onClose"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible && (_ctx.formType == 5 || _ctx.formType == 6),
      onClose: _ctx.cancel,
      erp: "",
      class: "fix-top-header"
    }, {
      default: _withCtx(() => [
        (_ctx.formType == 5 || _ctx.formType == 6)
          ? (_openBlock(), _createBlock(_component_check_ticket, {
              key: 0,
              editData: _ctx.rowData,
              formType: _ctx.formType,
              onCancel: _ctx.cancelCallBack
            }, null, 8, ["editData", "formType", "onCancel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onClose"])
  ], 64))
}