
import { defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import incomeTicket from './incomeTicket.vue';
import relationTicket from './relationTicket.vue';
import checkTicket from './checkTicket.vue';
import importReceivable from './importReceivable.vue';
import { erpConfirm, errorMessage, errorTip, exportFile } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
import { httpPost } from '@/api';

export default defineComponent({
  name: 'accrued',
  components: {
    ErpList,
    incomeTicket,
    relationTicket,
    checkTicket,
    importReceivable,
  },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const { startTimer, timeStatus } = useTimer();
    const customParams: any = ref({});
    const importReceiWidth = ref(1000) as any;
    const data = reactive({
      detailsData: null,
      version: '',
      orderArapId: '',
      improtVisible: false,
      category: 1,
      rowData: {
        id: null,
      },
    });
    const formType = ref(1);
    const preFormType = ref(null); // 上一个页面类开
    // 路由跳转
    const incomingTicket = () => {
      data.improtVisible = false;
      formType.value = 1;
      showVisible();
    };
    const changeImportWidth = (w) =>{
      console.log("🚀 ~ file: list.vue ~ line 85 ~ changeImportWidth ~ w", w);
      importReceiWidth.value = w;
    };
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const cancelCallBack = (isRefresh, callFormType, callPreFormType, id) => {
      // 如果为true 则刷新列表
      if (isRefresh) {
        //refreshTable();
      }
      // preFormType上一个页面类型
      if (!callFormType) {
        closeVisible();
      } else {
        formType.value = callFormType;
      }
      preFormType.value = null;
      if (callPreFormType) {
        preFormType.value = callPreFormType;
      }
      if (id) {
        data.rowData.id = id;
      }
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    const exportInFile = () => {
      formType.value = 99;
      importReceiWidth.value = 1000;
      data.improtVisible = true;
      showVisible();
    };
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/input/exportFmInputList',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };
    const auditForm = async (row) => {
      const res = await httpPost('/malicrm/orderArap/queryOrderArapDetailById', {
        orderArapId: row.orderArapId,
        arapType: 1,
      });

      if (res.code === 200) {
        data.detailsData = res.result;
        data.version = res.result.version;
        data.orderArapId = row.orderArapId;
        data.improtVisible = false;
        data.category = res.result.category;
        showVisible();
      } else {
        errorTip(res.message);
      }
    };
    // 查看详情
    const rowClick = (row, column) => {
      if (column.label !== '操作') {
        if (row.isOrderBinded) {
          // formType 1录入来票(新增)    2录入来票(编辑)  3关联来票弹窗  4重关联来票弹窗  5查看来票-未关联弹窗  6查看来票-已关联弹窗
          formType.value = 6;
        } else {
          // 未关联
          formType.value = 5;
        }
        preFormType.value = null;
        data.improtVisible = false;
        data.rowData = row;
        showVisible();
      }

      // 已关联
    };
    const relationFn = (row) => {
      formType.value = 3;
      preFormType.value = null;
      data.improtVisible = false;
      data.rowData = row;
      showVisible();
    };
    return {
      relationFn,
      cancelCallBack,
      preFormType,
      formType,
      incomingTicket,
      ...toRefs(data),
      exportInFile,
      auditForm,
      exportFileFn,
      cancel,
      closeVisible,
      customParams,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
      changeImportWidth,
      importReceiWidth
    };
  },
});
