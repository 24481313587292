/* eslint-disable @typescript-eslint/no-this-alias */
<template>
  <div>
    <div class="erp-form__header header-bar__sticky op-16">
      <div class="erp-form__title">录入来票</div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">保存</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="销售方名称" prop="sellerName">
                <div class="flex-box">
                  <el-select
                    v-model="formData.sellerType"
                    style="width: 160px"
                    class="append-no__border"
                    clearable
                    @change="sellerTypeChange"
                    placeholder="请选择"
                  >
                    <el-option v-for="item in filterOption.invoice_input_seller_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-select
                    v-if="formData.sellerType == 1 || formData.sellerType == 2 || !formData.sellerType"
                    v-model="formData.sellerName"
                    clearable
                    filterable
                    :disabled="!formData.sellerType"
                    remote
                    :remote-method="getSellerNameOption"
                    @blur="blurSellerNameOption"
                    placeholder="请输入销售方名称"
                    style="width: 299px; margin-left: 12px"
                    class="mali-full__inputcom"
                    @clear="clearSellerName"
                  >
                    <el-option :key="item.id" v-for="item in sellerNameOption" :label="item.fullName" :value="item.fullName"></el-option>
                  </el-select>
                  <el-input
                    v-if="formData.sellerType == 3"
                    :maxlength="40"
                    clearable
                    v-model="formData.sellerName"
                    placeholder="请输入销售方名称"
                    style="width: 299px; margin-left: 12px"
                    class="mali-full__inputcom"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="开票类型" prop="invoiceType">
                <el-select v-model="formData.invoiceType" class="mali-full__input" clearable placeholder="请选择开票类型">
                  <el-option v-for="item in filterOption.invoice_input_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本次来票金额" prop="totalAmount">
                <el-input
                  :maxlength="11"
                  clearable
                  v-model="formData.totalAmount"
                  placeholder="请输入本次来票金额"
                  @blur="validateTotalAmount"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开票日期" prop="invoiceCreatedTime">
                <el-date-picker
                  v-model="formData.invoiceCreatedTime"
                  type="date"
                  placeholder="请选择开票日期"
                  prefix-icon=" "
                  clearable
                  style="width: 100%"
                  @change="formatDate(formData, 'invoiceCreatedTime', $event)"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发票号" prop="invoiceNumber">
                <el-input
                  :maxlength="22"
                  v-model="formData.invoiceNumber"
                  placeholder="请输入发票号"
                  clearable
                  @blur="validateInvoiceNumber"
                  class="mali-full__inputcom"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="发票备注">
                <el-input
                  v-model="formData.remark"
                  placeholder="请输入发票备注"
                  :maxlength="100"
                  class="mali-full__input11"
                  type="textarea"
                  :autosize="{ minRows: 3 }"
                  resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <div class="erp-form__title f16">发票原文件</div>
            <el-col :span="24" style="margin-top: 20px">
              <el-form-item label="来票文件" prop="attachmentDto.url">
                <div class="upload-box" style="overflow: hidden; width: 100%">
                  <SingleFileContent
                    :readOnly="readOnly"
                    style="margin-left: 6px"
                    :fromType="formType"
                    :max="1"
                    :fileUrlsArray="fileUrlsArray"
                    fileType="noZip"
                    @success="getFileUrls"
                  >
                  </SingleFileContent>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import { store } from '@/store';
import { formatTime } from '@/utils/publicMethods';
export default {
  components: {
    SingleFileContent,
  },
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
    id: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: null,
    },
    preFormType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
    showData: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      fileType: '.pdf, .doc, .docx, .jpg, .png, .gif, .txt, .doc, .xls, .xlsx',
      fileTypeTips: '允许类型：pdf,doc,docx,jpg,png,gif,txt,xls,xlsx     大小上限：30M',
      titleImage: '', // 图片标题
      fileNameArr: [], // 文件名称
      fileUrlArr: [], // 图片地址
      fileUrlsArray: [],
      formData: {
        // 绑定表单数据
        id: null,
        sellerType: null, // 销售方类型
        sellerName: null, // 销售方名称
        invoiceType: null, // 开票类型
        totalAmount: null, // 本次来票金额
        invoiceCreatedTime: null, // 开票日期
        invoiceNumber: null, // 发票号
        remark: null,
        attachmentDto: {
          name: null,
          url: null,
        },
        version: null,
      },
      disabled: false,
      rules: {
        // 检验规则
        sellerName: [{ required: true, message: '请输入销售方名称', trigger: 'change' }],
        invoiceType: [{ required: true, message: '请选择开票类型', trigger: 'change' }],
        totalAmount: [{ required: true, message: '请输入本次来票金额', trigger: 'change' }],
        invoiceCreatedTime: [{ required: true, message: '请选择开票日期', trigger: 'change' }],
        invoiceNumber: [
          { required: true, message: '请输入发票号', trigger: 'blur' },
          {
            min: 8,
            max: 22,
            message: '发票号为8-22位',
          },
        ],
        // 'attachmentDto.url': [{required: true, message: '请选择来票文件', trigger: 'change'}]
      },
      filterOption: {
        invoice_input_type: store.state.dict.options.invoice_input_type, // 来票类型

        invoice_input_seller_type: store.state.dict.options.invoice_input_seller_type, // 来票销售方类型
      },
      sellerNameOption: [], // Option销售方名称
    };
  },
  computed: {
    readOnly() {
      return this.formType > 2; // 新增 编辑允许修改
    },
  },
  created() {
    this.getSellerNameOption('');
    if (this.formType === 2) {
      this.getinitdata();
    }
  },
  methods: {
    formatDate(item, name, value) {
      item[name] = formatTime(value, 'YYYY-MM-DD');
    },
    clearSellerName() {
      this.getSellerNameOption('');
    },
    getinitdata() {
      this.ajax({
        type: 'GET',
        url: '/malicrm/input/invoiceDetail/' + this.id,
        data: null,
        success: (res) => {
          if (res.code === 200) {
            this.formData = Object.assign({}, this.formData, res.result);
            if (res.result.attachmentVo && res.result.attachmentVo.url) {
              this.formData.attachmentDto = res.result.attachmentVo;
              const fileObj = {
                name: res.result.attachmentVo.name,
                url: res.result.attachmentVo.url,
              };
              this.fileUrlsArray.push(fileObj);
            }
            this.getSellerNameOption('');
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    // 校验本次来票金额
    validateTotalAmount(e) {
      let value = e.target.value;
      value = value.replace(/[^\d.-]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.indexOf('.') === 0) {
        value = '0' + value.substr(0, 3);
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 99999999.99) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 8);
        }
      }
      if (Number(value)) {
        this.formData.totalAmount = Number(value);
      } else {
        this.formData.totalAmount = null;
      }
    },
    // 校验发票号
    validateInvoiceNumber(e) {
      let value = e.target.value;
      value = value.replace(/[^\da-zA-Z-]/g, '');
      this.formData.invoiceNumber = value;
    },
    sellerTypeChange() {
      this.formData.sellerName = null;
      this.getSellerNameOption('');
    },
    getSellerNameOption(name) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      // 销售方类型
      const sellerType = this.formData.sellerType;
      if (!sellerType) {
        return;
      }
      let url;
      const data = {};
      if (sellerType === 1) {
        url = '/malicrm/funding/queryByFullName';
        data.fullName = name;
      }
      if (sellerType === 2) {
        url = '/malicrm/storage/queryByFullName';
        data.storageName = name;
      }
      // 获取销售方名称
      this.ajax({
        type: 'POST',
        url,
        loading: false,
        data,
        success: (res) => {
          if (res.code === 200) {
            if (res.result.length > 0) {
              that.sellerNameOption = res.result;
            } else {
              that.sellerNameOption = [];
            }
          }
        },
      });
    },
    blurSellerNameOption() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      if ((that.sellerNameOption && that.sellerNameOption.length <= 0) || !that.formData.sellerName) {
        setTimeout(function () {
          that.getSellerNameOption('');
        }, 500);
      }
    },
    getFileUrls(data) {
      console.log(data);
      this.formData.attachmentDto.name = data[0].name;
      this.formData.attachmentDto.url = data[0].url;
      this.fileUrlArr = data;
      this.disabled = false;
    },
    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveSubmitForm();
        }
      });
    },
    saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      this.ajax({
        type: 'POST',
        url: '/malicrm/input/operateFmInvoice',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            const id = res.result;
            // formType 1录入来票(新增)    2录入来票(编辑)  3关联来票弹窗  4重关联来票弹窗  5查看来票-未关联弹窗  6查看来票-已关联弹窗
            this.$emit('cancel', true, 5, null, id); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    back() {
      // 返回操作
      if (this.preFormType) {
        this.$emit('cancel', false, this.preFormType);
      } else {
        this.$emit('cancel', false);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
