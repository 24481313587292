import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref, toRaw } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '开票日期',
      prop: 'invoiceCreatedTime',
      minWidth: 130,
      value: [],
      type: 'time',
      filterProp: ['invoiceCreatedTimeStart', 'invoiceCreatedTimeEnd'],
    },
    {
      label: '发票号',
      prop: 'invoiceNumber',
      minWidth: 130,
      value: '',
      type: 'input',
    },
    {
      label: '销售方名称',
      prop: 'sellerName',
      minWidth: 180,
      value: '',
      type: 'input',
    },
    {
      label: '开票类型',
      prop: 'invoiceType',
      propDesc: 'invoiceTypeName',
      value: '',
      minWidth: 140,
      type: 'signSelect',
      options: 'invoice_input_type',
    },
    {
      label: '本次来票金额',
      prop: 'totalAmount',
      propDesc: 'totalAmountDesc',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['totalAmountMin', 'totalAmountMax'],
    },
    {
      label: '是否关联订单',
      prop: 'isOrderBinded',
      propDesc: 'isOrderBindedDesc',
      minWidth: 150,
      value: '',
      type: 'signSelect',
      options: [
        {
          value: 0,
          label: '否',
        },
        {
          value: 1,
          label: '是',
        },
      ],
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStart', 'createdTimeEnd'],
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '关联时间',
      prop: 'bindTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['bindTimeStart', 'bindTimeEnd'],
    },
    {
      label: '关联人',
      prop: 'bindOperator',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 88,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button  type="primary" plain  @click='relationFn(row)'  v-if="!row.isOrderBinded && hasPermission('erp:input:invoiceRefOrder')" >关联</el-button>
        `,
        props: ['row'],
        setup() {
          const relationFn = (row) => {
            Instance.setupState.relationFn(toRaw(row));
            return '';
          };
          return { relationFn };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
