<template>
  <div v-loading="loading" element-loading-text="正在导入，请勿进行其他操作">
    <div>
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">
          {{ step == 1 ? '导入来票项目' : '来票信息表' }}
        </div>
        <div class="erp-form-bar">
          <el-button size="small" type="primary" class="erp-primary__btn" @click="nextStep" v-if="step === 1">下一步</el-button>
          <el-button size="small" @click="showUpLoad" type="primary" class="erp-primary__btn" v-if="step === 2">上传来票文件</el-button>
          <el-button size="small" type="primary" class="erp-primary__btn" @click="submit" v-if="step === 2">保存</el-button>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <!-- 步骤一 -->
      <div v-if="step === 1">
        <div style="padding: 0 16px">
          <div class="erp-form__title f16 import-top req no-leftborder" style="">选择导入文件</div>
          <div class="upload-box">
            <SingleFileContent
              :uploadUrl="customUrl"
              :isImport="true"
              :fromType="1"
              :fileType="fileType"
              :queryNum="0"
              :max="1"
              :typeImg="1"
              tips=" "
              :fileUrlsArray="fileUrlsArray"
              @getFiles="getFiles"
              ref="importUpload"
              :autoUpload="false"
            >
            </SingleFileContent>
            <div class="upload-tips">
              允许类型：xls、xlsx 文件大小上限： 30M 只支持上传1个文件
              <span class="record__link" @click="downLoadTmp">《来票信息表》<span class="under">模板下载</span> </span>
            </div>
          </div>
        </div>
        <!-- 应收项目 -->
        <div v-if="errorShow">
          <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
            <div class="erp-form__title f16 no-leftborder">错误日志</div>
          </div>
          <div>
            <el-table :data="errorData" border class="egrid erp-table__list erp-view__table">
              <el-table-column label="错误行数" align="center" show-overflow-tooltip width="190" class-name="no-pd">
                <template #default="scope">
                  {{ filterName(scope.row.cellRowDesc) }}
                </template>
              </el-table-column>
              <el-table-column label="错误原因" align="center" show-overflow-tooltip class-name="no-pd">
                <template #default="scope">
                  {{ filterName(scope.row.description) }}
                </template>
              </el-table-column>
            </el-table>
            <div class="mali-erp-list-pagination">
              <pc-pagination
                ref="pcPagination"
                url="/malicrm/excel/queryExcelImportLineLogDetailList"
                :queryData="errorQueryForm"
                @change="queryCallBackFn"
              ></pc-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 步骤2 -->
      <div v-else>
        <el-table
          :data="formData.dtoList"
          border
          class="egrid erp-table__list erp-view__table table-loading"
          id="scrollTables"
          row-key="index"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="销售方类型" class-name="no-pd req" align="center" width="100" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.sellerTypeDesc) }}
            </template>
          </el-table-column>

          <el-table-column label="销售方名称" class-name="no-pd req" align="center" width="124" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.sellerName) }}
            </template>
          </el-table-column>
          <el-table-column label="开票类型" class-name="no-pd req" align="center" width="90" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.invoiceTypeDesc) }}
            </template>
          </el-table-column>
          <el-table-column label="本次开票金额" class-name="no-pd req" align="center" width="114" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.totalAmountDesc) }}
            </template>
          </el-table-column>
          <el-table-column label="开票日期" class-name="no-pd req" align="center" width="100" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.invoiceCreatedTime) }}
            </template>
          </el-table-column>
          <el-table-column label="发票号" class-name="no-pd req" align="center" width="100" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.invoiceNumber) }}
            </template>
          </el-table-column>
          <el-table-column label="销售合同号" class-name="no-pd req" align="center" width="140" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.businessContractNo) }}
            </template>
          </el-table-column>
          <el-table-column label="发票品类" class-name="no-pd " align="center" width="90" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.invoiceVarietyDesc) }}
            </template>
          </el-table-column>
          <el-table-column label="已来发票金额" class-name="no-pd req" align="center" width="110" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.invoiceAmountDesc) }}
            </template>
          </el-table-column>
          <el-table-column label="已来发票税额" class-name="no-pd req" align="center" width="110" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.invoiceTaxAmountDesc) }}
            </template>
          </el-table-column>
          <el-table-column label="发票备注" class-name="no-pd " align="center" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.remark) }}
            </template>
          </el-table-column>

          <el-table-column label="来票文件" class-name="no-pd" align="center" width="110">
            <template #default="scope">
              <template v-if="scope.row.attachmentDto">
                <div class="record__link" @click="checkClick(scope.row)">查看</div>
              </template>
              <template v-else> - </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <tableFileContent
      class="margin-upload"
      width="970px"
      v-if="showUpLoadVisable"
      :maxImg="1"
      :size="30"
      @getFileUrls="getFinalPayFile"
      ref="tableFileUpLoad"
      setKeyName="name"
      setKeyUrl="url"
      keyName="name"
      keyUrl="url"
      :fileUrlsArray="files"
    >
      <div></div>
    </tableFileContent>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import PcPagination from '@/components/Pagination/Pagination.vue';
export default {
  components: {
    SingleFileContent,
    tableFileContent,
    PcPagination,
  },
  props: {},
  data() {
    return {
      loading: false,
      errorShow: false,
      isAjaxLoading: false,
      errorData: [], // 错误信息列表
      errorQueryForm: {
        logId: null,
        pageNum: 1,
        pageSize: 10,
      },
      showUpLoadVisable: false, // 展示批量上传文件展示
      files: [],
      step: 1,
      customUrl: process.env.VUE_APP_API_ROOT + '/malicrm/input/importFmInvoice',
      fileType: 'excel',
      fileUrlsArray: [],
      selectionList: [],
      formData: {
        dtoList: [],
      },
    };
  },

  methods: {
    queryCallBackFn(res) {
      this.errorData = res;
      // if (res.code === 200) {
      //   this.errorData = res.result.list;
      // }
    },
    selectionChange(row) {
      // 获取选中的行数据
      this.selectionList = row;
    },
    nextStep() {
      // 校验下一步,触发手动上传
      const len = this.$refs.importUpload.fileCount;
      if (len > 0) {
        this.$refs.importUpload.submitUpload();
        this.loading = true;
      } else {
        this.errorTip('请先上传文件。');
      }
    },
    downLoadTmp() {
      // 下载模板地址
      this.$showFiles('来票信息表.xlsx', 'https://minio.malleegroup.com/template/rBEABmAzG4GAIksXAAAk0v2YKnE35.xlsx', false);
    },
    getFiles(res) {
      // 获取导入数据，构建数据列
      this.loading = false;
      if (res.code === 200) {
        if (res.result.hasError === true) {
          this.errorTip('导入失败');
          this.errorQueryForm.logId = res.result.importLogId;
          this.errorShow = false;
          setTimeout(() => {
            this.errorShow = true;
          }, 50);
        } else {
          this.successTip('导入成功');
          const data = res.result.dtoList;
          data.forEach((v, index) => {
            v.index = index;
          });

          this.formData.dtoList = data;
          this.step = 2;
          this.$emit('changeWidth', 1396);
        }
      } else {
        this.errorTip(res.message);
      }
      this.$nextTick(() => {
        if (this.$refs.importUpload) {
          this.$refs.importUpload.fileCount = 0; // 必须置空，否则直接上传不会触发;
        }
        this.fileUrlsArray = [];
      });
    },
    // 点击查看文件
    checkClick(row) {
      let { name, url } = row.attachmentDto;
      this.$showFiles(name, url);
    },
    getLogData() {
      // 获取错误日志信息
      this.ajax({
        type: 'POST',
        url: '/malicrm/excel/queryExcelImportLineLogDetailList',
        data: {},
        success: (res) => {
          if (res.code === 200) {
            this.errorData = res.result.list;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    getRowFileUrls({ data, index }) {
      // 单行文件编辑修改
      this.formData.dtoList[index].attachmentDto = data[0];

      // 行文件标记
    },
    getFinalPayFile({ data }) {
      // 获取上传的文件
      const indexs = this.getIndexs(this.formData.dtoList, this.selectionList, 'index');
      indexs.forEach((index) => {
        this.formData.dtoList[index].attachmentDto = data[0];
      });
    },
    showUpLoad() {
      // 校验打开上传文件
      if (this.selectionList.length > 0) {
        this.showUpLoadVisable = false;
        setTimeout(() => {
          this.showUpLoadVisable = true;
        }, 10);
        setTimeout(() => {
          this.$refs.tableFileUpLoad.uploadVisable = true;
        }, 100);
      } else {
        this.errorTip('请选择关联数据');
      }
    },
    submit() {
      if (this.validForm()) {
        if (!this.isAjaxLoading) {
          this.isAjaxLoading = true;
          this.ajax({
            type: 'POST',
            url: '/malicrm/input/saveFmInvoiceFromExcel',
            data: {
              dtoList: this.selectionList,
            },
            success: (res) => {
              this.isAjaxLoading = false;
              if (res.code === 200) {
                this.successTip('提交成功');
                this.$emit('cancel', true);
              } else {
                this.errorTip(res.message);
              }
            },
            error: () => {
              this.isAjaxLoading = false;
            },
          });
        }
      }
    },
    validForm() {
      const data = this.formData.dtoList;
      const status = data.length;
      // let status = data.every(v => v.attachmentDto && v.attachmentDto.url !== '');
      // if (!status) {
      //   this.errorTip('必须上传来票文件');
      // }
      return status;
    },
    back() {
      // 返回操作
      if (this.step === 2) {
        this.step = 1;
        this.$emit('changeWidth', 1000);
      } else {
        this.$emit('cancel', false);
      }
    },
    getIndexs(targetArrays, currentArrays, name) {
      // 获取对应下标值
      const indexs = [];
      currentArrays.forEach((v) => {
        indexs.push(targetArrays.findIndex((item) => item[name] === v[name]));
      });
      return indexs;
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-tips {
  display: flex;
  align-items: center;
  white-space: pre;
  font-size: 14px;
  color: #9a9a9a;
  position: absolute;
  top: 192px;
  margin-left: 16px;
  .record__link {
    color: #2878ff;
  }
  .under {
    text-decoration: underline;
  }
}
.import-top {
  margin-bottom: 20px;
  margin-top: 20px;
}
.upload-box {
  position: relative;
}
.erp-form__title.req::after {
  content: '*';
  color: #f04838;
  margin-left: 4px;
}
.erp-form__title.no-leftborder {
  border-left: none;
  padding-left: 0;
}
</style>
