import { resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_pagination, {
    total: _ctx.total,
    "current-page": _ctx.pageNum,
    "page-size": _ctx.pageSize,
    layout: "prev, pager, next,jumper'",
    onCurrentChange: _ctx.currentChange,
    onSizeChange: _ctx.currentSize,
    background: !_ctx.isSimple
  }, null, 8, ["total", "current-page", "page-size", "onCurrentChange", "onSizeChange", "background"])), [
    [_vShow, _ctx.total > _ctx.showTotal]
  ])
}