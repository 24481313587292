<template>
  <div>
    <div>
      <div class="erp-form__header header-bar__sticky pd16">
        <div class="erp-form__title">
          {{ formType === 3 ? '关联来票' : '重新关联来票' }}
        </div>
        <div class="erp-form-bar">
          <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">保存</el-button>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <div class="order-base__warp op-16">
        <el-row :gutter="30" type="flex" class="flex-warp">
          <el-col :span="12">
            <erp-view label="本次来票金额" :value="baseData.totalAmountDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="开票类型" :value="baseData.invoiceTypeName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="发票备注" :value="baseData.remark"></erp-view>
          </el-col>
        </el-row>
      </div>
      <div>
        <!-- 应收项目 -->
        <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
          <div class="erp-form__title f16">来票订单</div>
          <div class="edit-btn__bar">
            <span>已来发票合计：{{ getTotal }}</span>
          </div>
        </div>
        <div>
          <el-table
            id="tables"
            :data="formData.fmRefDtos"
            show-overflow-tooltip
            border
            class="egrid erp-table__list erp-view__table line-height1"
            :class="{ 'no-shadow': !shadow }"
            max-height="692"
          >
            <el-table-column align="center" class-name="no-pd table-visble required-icon" width="190">
              <template v-slot:header>
                <span>销售合同号 <span class="icon-must">*</span></span>
              </template>
              <template #default="scope">
                <div>
                  <el-select
                    v-model="scope.row.businessContractNo"
                    clearable
                    filterable
                    remote
                    :disabled="scope.row.status === 3 && formType === 4"
                    @change="contractNoChange($event, scope.$index)"
                    :remote-method="getContractNoOption"
                    @blur="blurContractNoOption(scope.$index)"
                    :trigger-on-focus="false"
                    @focus="focusContractNoOption(scope.$index)"
                    placeholder=""
                    @clear="clearContract(scope.$index)"
                  >
                    <el-option
                      :key="item.orderId"
                      v-for="item in scope.row.ContractNoOption"
                      :label="item.businessContractNo"
                      :value="item.businessContractNo"
                    ></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="contractPrincipalName" align="center" class-name="no-pd" show-overflow-tooltip width="120">
              <template v-slot:header>
                <span>签约主体</span>
              </template>
              <template #default="scope">
                <div>
                  {{ scope.row.contractPrincipalName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="notApAmountManual" align="center" class-name="no-pd" show-overflow-tooltip width="180">
              <template v-slot:header>
                <span>未来票金额</span>
              </template>
              <template #default="scope">
                <div>
                  {{ scope.row.notApAmountManualDesc }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="goodsInvoiceAmount" align="center" class-name="no-pd" show-overflow-tooltip width="140">
              <template v-slot:header>
                <span>已来货物发票金额<br/>（不含税）</span>
              </template>
              <template #default="scope">
                <el-input
                  clearable
                  :disabled="scope.row.status === 3 && formType === 4"
                  v-model="scope.row.goodsInvoiceAmount"
                  :maxlength="11"
                  @change="amountRateChange(scope.$index, 'goodsInvoiceAmount', 'goodsInvoiceTaxAmount', 0.09)"
                  @blur="validateNum($event, scope, 'goodsInvoiceAmount')"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="goodsInvoiceTaxAmount" align="center" class-name="no-pd" show-overflow-tooltip width="140">
              <template v-slot:header>
                <span>已来货物发票<br/>税额</span>
              </template>
              <template #default="scope">
                <el-input
                  :disabled="scope.row.status === 3 && formType === 4"
                  clearable
                  :maxlength="11"
                  v-model="scope.row.goodsInvoiceTaxAmount"
                  @blur="validateNum($event, scope, 'goodsInvoiceTaxAmount')"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="serviceInvoiceAmount" class-name="no-pd" show-overflow-tooltip width="140">
              <template v-slot:header>
                <span>已来服务发票金额<br/>（不含税） </span>
              </template>
              <template #default="scope">
                <el-input
                  clearable
                  :disabled="scope.row.status === 3 && formType === 4"
                  v-model="scope.row.serviceInvoiceAmount"
                  :maxlength="11"
                  @change="amountRateChange(scope.$index, 'serviceInvoiceAmount', 'serviceInvoiceTaxAmount', 0.06)"
                  @blur="validateNum($event, scope, 'serviceInvoiceAmount')"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="serviceInvoiceTaxAmount" class-name="no-pd" show-overflow-tooltip width="140">
              <template v-slot:header>
                <span>已来服务发票<br/>税额 </span>
              </template>
              <template #default="scope">
                <el-input
                  :disabled="scope.row.status === 3 && formType === 4"
                  v-model="scope.row.serviceInvoiceTaxAmount"
                  clearable
                  :maxlength="10"
                  @blur="validateNum($event, scope, 'serviceInvoiceTaxAmount')"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column width="48" class-name="no-pd" align="center">
              <template v-slot:header>
                <span v-if="formData.fmRefDtos.length < 20" class="el-icon-circle-plus vote" style="font-size: 18px" @click="addOrder"></span>
                <span v-else></span>
              </template>
              <template #default="scope">
                <span class="list-del" @click="deleteOrder(scope.$index)" v-if="!(scope.row.status === 3 && formType === 4)">
                  <img src="../../../../assets/images/list-del.png" alt="" />
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    formType: { type: [String, Number] },
    preFormType: { type: [String, Number] }, // 上一个页面类型
    id: { type: [String, Number] }, // 订单id
    version: { type: [String, Number] },
    data: {
      type: [Object, Array],
    },
  },
  computed: {
    getTotal() {
      let sum = 0;
      this.formData.fmRefDtos.forEach((item) => {
        sum += Number(item.goodsInvoiceAmount);
        sum += Number(item.goodsInvoiceTaxAmount);
        sum += Number(item.serviceInvoiceAmount);
        sum += Number(item.serviceInvoiceTaxAmount);
      });
      const reg = /(\d)(?=(\d{3})+(?!\d))/g;
      sum = sum.toFixed(2);
      sum = sum.toString().replace(reg, '$1,');
      return sum;
    },
  },
  data() {
    return {
      shadow: true,
      baseData: {},
      formData: {
        fmRefDtos: [
          {
            businessContractNo: null, // 销售合同号
            ContractNoOption: null, // 销售合同号Option
            notApAmountManual: null, // 未来票金额
            contractPrincipalName: null, // 签约主体
            notApAmountManualDesc: null, // 未来票金额
            goodsInvoiceAmount: null, // 已来货物发票金额(不含税)(元)
            goodsInvoiceTaxAmount: null, // 已来货物发票税额(元)
            orderId: null, // 订单id
            serviceInvoiceAmount: null, // 已来服务发票金额(不含税)(元)
            serviceInvoiceTaxAmount: null, // 已来服务发票税额(元)}
          },
        ],
        invoiceInputId: null, // 来票id
        version: null, // 来票版本号(仅重新关联时用)
      },
      contractOptionIndex: 0,
    };
  },
  mounted() {
    const el = document.querySelector('#tables');
    el.addEventListener('scroll', () => {
      if (el.scrollLeft > 108) {
        this.shadow = false;
      } else {
        this.shadow = true;
      }
    });
  },
  created() {
    this.formData.invoiceInputId = this.id;
    this.formData.version = this.version;
    this.initData();
    if (this.formType === 3) {
      this.getContractNoOption('');
    }
  },
  methods: {
    initData() {
      const id = this.id;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      that.ajax({
        type: 'GET',
        url: '/malicrm/input/invoiceDetail/' + id,
        loading: false,
        success: (res) => {
          if (res.code === 200) {
            that.baseData = res.result;
            that.formData.version = res.result.version;
            if (that.formType === 4) {
              const tempList = res.result.inputOrderVos;
              tempList.forEach((item, index) => {
                this.formData.fmRefDtos[index] = item;

                that.contractOptionIndex = index;
                that.getContractNoOption('');
              });
            }
          }
        },
      });
    },
    deleteOrder(index) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const data = that.formData.fmRefDtos[index];
      let hasVal = true;
      if (
        data.businessContractNo ||
        (data.notApAmountManualDesc !== null && data.notApAmountManualDesc !== '') ||
        (data.goodsInvoiceAmount !== null && data.goodsInvoiceAmount !== '') ||
        (data.goodsInvoiceTaxAmount !== null && data.goodsInvoiceTaxAmount !== '') ||
        (data.serviceInvoiceAmount !== null && data.serviceInvoiceAmount !== '') ||
        (data.serviceInvoiceTaxAmount !== null && data.serviceInvoiceTaxAmount !== '')
      ) {
        hasVal = false;
      }
      if (!hasVal) {
        this.$erpConfirm('确定删除吗？')
          .then(() => {
            that.formData.fmRefDtos.splice(index, 1);
          })
          .catch(() => {
            return false;
          });
      } else {
        that.formData.fmRefDtos.splice(index, 1);
      }
    },
    addOrder() {
      if (this.formData.fmRefDtos.length >= 20) {
        return;
      }
      const order = {
        businessContractNo: null, // 销售合同号
        ContractNoOption: null, // 销售合同号Option
        contractPrincipalName: null, // 签约主体
        notApAmountManual: null, // 未来票金额
        notApAmountManualDesc: null, // 未来票金额 描述
        goodsInvoiceAmount: null, // 已来货物发票金额(不含税)(元)
        goodsInvoiceTaxAmount: null, // 已来货物发票税额(元)
        orderId: null, // 订单id
        serviceInvoiceAmount: null, // 已来服务发票金额(不含税)(元)
        serviceInvoiceTaxAmount: null, // 已来服务发票税额(元)}
      };
      this.formData.fmRefDtos.push(order);
      this.contractOptionIndex = this.formData.fmRefDtos.length - 1;
      this.getContractNoOption('');
    },
    amountRateChange(index, prop, prop2, rate) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      setTimeout(function () {
        const num = that.formData.fmRefDtos[index][prop];
        if (Number(num)) {
          let result = num * rate;
          result = Number(result.toFixed(2));
          if (!result) {
            result = null;
          }
          that.formData.fmRefDtos[index][prop2] = result;
        } else {
          that.formData.fmRefDtos[index][prop2] = null;
        }
      }, 50);
    },
    // 校验申请付款金额
    validateNum(e, scope, name) {
      let value = e.target.value;
      value = value.replace(/[^\d.-]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.indexOf('.') === 0) {
        value = '0' + value.substr(0, 3);
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 99999999.99) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 8);
        }
      }
      if (Number(value)) {
        scope.row[name] = Number(value);
      } else {
        scope.row[name] = null;
      }
    },
    blurContractNoOption(index) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const option = this.formData.fmRefDtos[index].ContractNoOption;
      if ((option && option.length <= 0) || !this.formData.fmRefDtos[index].businessContractNo) {
        setTimeout(function () {
          that.getContractNoOption('');
        }, 500);
      }
    },
    clearContract() {
      this.getContractNoOption('');
    },
    focusContractNoOption(index) {
      this.contractOptionIndex = index;
    },
    /**
     * 销售合同下拉搜索
     * @param name
     */
    getContractNoOption(name, all) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const index = this.contractOptionIndex;
      // 获取销售合同号
      this.ajax({
        type: 'POST',
        url: '/malicrm/input/queryOrderByContractNo',
        loading: false,
        data: { businessContractNo: name },
        success: (res) => {
          if (res.code === 200) {
            if (!all) {
              that.formData.fmRefDtos[index].ContractNoOption = res.result;
            } else {
              that.formData.fmRefDtos.forEach((item, index2) => {
                that.formData.fmRefDtos[index2].ContractNoOption = res.result;
              });
            }
          }
        },
      });
    },
    // 销售合同号选择事件
    contractNoChange(val, index) {
      const oindex = this.formData.fmRefDtos[index].ContractNoOption.findIndex((item) => {
        return item.businessContractNo === val;
      });
      const ContractNoOptionObj = this.formData.fmRefDtos[index].ContractNoOption[oindex];
      // 销售合同号里 获取 未来票金额 contractPrincipalName: null, // 签约主体
      if (ContractNoOptionObj && ContractNoOptionObj.notApAmountManualDesc) {
        this.formData.fmRefDtos[index].contractPrincipalId = ContractNoOptionObj.contractPrincipalId;
        this.formData.fmRefDtos[index].contractPrincipalName = ContractNoOptionObj.contractPrincipalName;
        this.formData.fmRefDtos[index].notApAmountManualDesc = ContractNoOptionObj.notApAmountManualDesc;
        this.formData.fmRefDtos[index].notApAmountManual = ContractNoOptionObj.notApAmountManual;
        this.formData.fmRefDtos[index].orderId = ContractNoOptionObj.orderId;
      } else {
        this.formData.fmRefDtos[index].contractPrincipalId = null;
        this.formData.fmRefDtos[index].contractPrincipalName = null;
        this.formData.fmRefDtos[index].notApAmountManualDesc = null;
        this.formData.fmRefDtos[index].notApAmountManual = null;
        this.formData.fmRefDtos[index].orderId = null;
      }
    },
    validateData() {
      if (this.formData.fmRefDtos.length < 1) {
        this.errorTip('请填写所有带星号的必填项');
        return false;
      }
      let valid = true;
      valid = this.formData.fmRefDtos.every((item) => {
        return Boolean(item.businessContractNo);
      });
      if (!valid) {
        this.errorTip('请填写所有带星号的必填项');
        return valid;
      }
      let sum = 0;
      this.formData.fmRefDtos.forEach((item) => {
        sum += Number(item.goodsInvoiceAmount);
        sum += Number(item.goodsInvoiceTaxAmount);
        sum += Number(item.serviceInvoiceAmount);
        sum += Number(item.serviceInvoiceTaxAmount);
      });
      sum = sum.toFixed(2);
      if (Number(this.baseData.totalAmount) !== Number(sum)) {
        valid = false;
        this.errorTip('本次来票金额不等于订单的发票金额合计');
      }
      const len = this.formData.fmRefDtos.length;
      if (len > 1) {
        const businessContractNoArr = this.formData.fmRefDtos.map((item) => {
          return item.businessContractNo;
        });
        for (let i = 0; i < businessContractNoArr.length; i++) {
          const item = businessContractNoArr[i];
          if (businessContractNoArr.indexOf(item) !== businessContractNoArr.lastIndexOf(item)) {
            this.errorTip('请勿填写重复的销售合同号');
            valid = false;
          }
        }
        if(valid){
          const contractPrincipalIdArr = this.formData.fmRefDtos.map((item) => {
            return item.contractPrincipalId;
          });
          for (let i = 0; i < contractPrincipalIdArr.length; i++) {
            const itemId = contractPrincipalIdArr[i];
            let result = contractPrincipalIdArr.every(item => item === itemId);
            if (!result) {
              this.errorTip('关联来票销售合同的签约主体必须相同');
              valid = false;
              break;
            }
          }
        }
      }
      return valid;
    },
    submit() {
      const status = this.validateData();
      if (status) {
        this.ajax({
          type: 'POST',
          url: '/malicrm/input/invoiceRefOrder',
          data: this.formData,
          success: (res) => {
            if (res.code === 200) {
              this.successTip('保存成功', 3000);
              // formType 1录入来票(新增)    2录入来票(编辑)  3关联来票弹窗  4重关联来票弹窗  5查看来票-未关联弹窗  6查看来票-已关联弹窗
              this.$emit('cancel', true, 6);
            } else {
              this.errorTip(res.message);
            }
          },
        });
      }
    },
    back() {
      // 返回操作
      if (this.preFormType) {
        this.$emit('cancel', false, this.preFormType);
      } else {
        this.$emit('cancel', false);
      }
    },
  },
  filters: {
    filterName(val) {
      if (val === null || val === '') {
        return '-';
      } else {
        return val;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
.icon-must {
  color: #f04737;
  font-size: 16px;
  position: relative;
  top: 2px;
}
.el-icon-circle-plus {
  cursor: pointer;
}
.erp-form__header.pd16 {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
