
import { httpPost, httpGet } from '@/api';
import { defineComponent, PropType, reactive, ref, toRefs } from 'vue';
export default defineComponent({
  props: {
    url: {
      type: String as PropType<string>,
      default: '',
    },
    method: {
      type: String as PropType<string>,
      default: 'POST',
    },
    queryData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSimple: {
      type: Boolean,
      default: true,
    },
    showTotal: {
      type: Number,
      default: 10,
    },
    isOfferCounter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, ctx) {
    const paginationParams = reactive({
      pageNum: 1,
      pageSize: props.showTotal as number,
      total: 0,
    });
    const listData = ref([]);
    const getTableDatas = (filterParams) => {
      if (props.method === 'POST') {
        httpPost(props.url as string, filterParams).then((res) => {
          if (res.code === 200) {
            if (props.isOfferCounter) {
              listData.value = res.result.myDemandListVos.list;
              paginationParams.total = Number(res.result.myDemandListVos.total);
              ctx.emit('change', listData.value, paginationParams.total, res.result);
            } else {
              if (res.result.list) {
                listData.value = res.result.list;
                paginationParams.total = Number(res.result.total);
                ctx.emit('change', listData.value, paginationParams.total, res.result);
              } else if (res.result) {
                listData.value = res.result;
                paginationParams.total = Number(res.result.length);
                ctx.emit('change', listData.value, paginationParams.total);
              }
            }
          } else {
            listData.value = [];
            ctx.emit('change', listData.value, paginationParams.total);
          }
        });
      } else {
        httpGet(props.url as string, filterParams).then((res) => {
          if (res.code === 200) {
            listData.value = res.result;
            ctx.emit('change', listData.value, listData.value.length);
          }
        });
      }
      console.log("🚀 ~ file: Pagination.vue ~ line 69 ~ httpPost ~ paginationParams", paginationParams);

    };
    // 获取条数
    const currentSize = (size) => {
      paginationParams.pageSize = size;
      const filterParams = Object.assign(props.queryData, { pageSize: size });
      getTableDatas(filterParams);
    };
    // 获取页码
    const currentChange = (page) => {
      paginationParams.pageNum = page;
      const filterParams = Object.assign(props.queryData, { pageNum: page });
      getTableDatas(filterParams);
    };
    getTableDatas(props.queryData);
    const refashTable = () => {
      getTableDatas(props.queryData);
    };
    return {
      currentSize,
      currentChange,
      ...toRefs(paginationParams),
      refashTable,
    };
  },
});
